import { graphql, Link } from "gatsby";
import Image from "gatsby-image";
import React from "react";
import SEO from "../components/seo";
import TimeTable from "../components/timetable";
import schedule from "../data/class_schedule";
import { filterLessons } from "../tools/utils";

const Chippenham = ({ data, location }) => {
  const lessons = filterLessons(schedule, 'chippenham');
  return (
    <>
      <SEO
        title="King Alfred Hall"
        keywords={[`king alfred hall`, `chippenham`, `dance`, `lessons`]}
        location={location}
      />
      <Link
        to="/lessons#schedule"
        className="hover:underline text-highlight font-semibold mb-4"
      >
        &larr;Back to Class Schedule
      </Link>
      <article className="flex flex-col items-center md:flex-row md:items-start md:justify-around">
        <div className="text-justify">
          <h2>King Alfred Hall</h2>
          <p>
            St Andrew's Church
            <br />
            Market Place
            <br />
            Chippenham
            <br />
            SN15 3HT
          </p>
        </div>

        <figure className="mt-5 w-4/5 md:w-1/3 md:mt-0 self-center">
          <Image
            className="rounded"
            fluid={data.venue.sharp.fluid}
            alt="La Scala Studio"
          />
          <figcaption className="text-xs">Inside King Alfred Hall.</figcaption>
        </figure>
      </article>
      <section className="md:mx-auto">
        <h2 className="mt-4">Class Times</h2>
        <TimeTable data={lessons} />
      </section>
    </>
  );
};

export const query = graphql`
  query {
    venue: file(relativePath: { eq: "king-alfred-hall.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Chippenham;